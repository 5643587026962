'use strict'

/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'

axios.defaults.withCredentials = true

// 环境的切换
axios.defaults.baseURL = '/forum'

// 请求超时时间
axios.defaults.timeout = 100000

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 请求拦截器
axios.interceptors.request.use(
  config => {
    config.headers.token = localStorage.getItem('pc-token') || ''
    config.headers.language = 'EN'
    const language = localStorage.getItem('language')
    if (language) {
      config.headers.language = language.toUpperCase()
    }
    return config
  },
  error => {
    return Promise.error(error)
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const resp = response.data
      const status = resp.httpStatus
      if (status === 500 || status === 400 || status === 403) {
        alertMessage(resp.data)
      } else if (status === 401 || status === 431) {
        jumpLogin(resp.data)
      } else {
        return Promise.resolve(response)
      }
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response && error.response.data) {
      alertMessage(error.response.data.message)
    } else {
      alertMessage(error.message || '接口调用出错')
    }
    console.log(error)
  }
)

axios.jsonp = (url, data) => {
  if (!url) {
    alertMessage('Axios.JSONP 至少需要一个url参数!')
    return
  }
  const callback = 'CALLBACK' + Math.random().toString().substr(9, 18)
  const JSONP = document.createElement('script')
  JSONP.setAttribute('type', 'text/javascript')
  const headEle = document.getElementsByTagName('head')[0]
  let ret = ''
  if (data) {
    if (typeof data === 'string') {
      ret = '&' + data
    } else if (typeof data === 'object') {
      for (const key in data) {
        ret += '&' + key + '=' + encodeURIComponent(data[key])
      }
    }
    ret += '&_time=' + Date.now()
  }
  JSONP.src = `${url}?callback=${callback}${ret}`
  return new Promise((resolve) => {
    window[callback] = r => {
      resolve(r)
      headEle.removeChild(JSONP)
      delete window[callback]
    }
    headEle.appendChild(JSONP)
  })
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const getRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const downloadFile = (url, filename = 'file.unknown') => {
  axios({
    url: url,
    method: 'get',
    responseType: 'blob' // 重要！表明返回服务器返回的数据类型
  }).then((response) => {
    // 创建一个blob对象
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    // 创建一个包含要下载文件的URL对象
    const downloadUrl = window.URL.createObjectURL(blob)
    // 创建一个a标签用于下载
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', filename) // 或者你可以从响应头中获取文件名
    document.body.appendChild(link)
    link.click() // 触发下载
    // 清理
    document.body.removeChild(link)
    window.URL.revokeObjectURL(downloadUrl)
  }).catch((error) => {
    console.error('Error downloading file:', error)
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export const postRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(params)).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const postJsonRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const uploadRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const putRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.put(url, params, {
      transformRequest: [function (data) {
        let ret = ''
        for (const it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const putJsonRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.put(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.data)
      })
  })
}

export const deleteRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      params: params,
      method: 'delete',
      url: `${url}`
    }).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const patchRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      params: params,
      method: 'patch',
      url: `${url}`
    }).then(res => { resolve(res.data) }).catch(err => { reject(err.data) })
  })
}

export const jsonpRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.jsonp(url, params).then(res => { resolve(res) }).catch(err => { reject(err) })
  })
}

export const downloadExcel = (url, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      params: params,
      responseType: 'blob'
    }).then((res) => {
      if (res.data.type.indexOf('json') >= 0) {
        const reader = new FileReader()
        reader.onload = function (event) {
          // event.target.result 将包含Blob的内容，作为一个DataURL字符串
          const jsonString = event.target.result // 实际上，对于JSON，我们不需要DataURL的完整格式，但这里仍然可以工作
          try {
            const jsonObject = JSON.parse(jsonString)
            resolve({ success: false, message: jsonObject.data })
          } catch (error) {
            resolve({ success: false, message: error.message })
          }
        }
        reader.readAsText(res.data) // 读取Blob为文本（默认编码为UTF-8）
      } else {
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', params.fileName) // 或者你可以从服务器响应中获取文件名
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
        resolve({ success: true })
      }
    }).catch(err => { reject(err) })
  })
}

/* eslint-disable */
export const rawRequest = function icAjax (opt) {
  opt = opt || {}
  opt.method = opt.method.toUpperCase() || 'POST'
  opt.url = opt.url || ''
  if (opt.async !== false) {
    opt.async = true
  }
  opt.data = opt.data || null
  opt.success = opt.success || function () {
  }
  var xmlHttp = null
  if (XMLHttpRequest) {
    xmlHttp = new XMLHttpRequest()
  } else {
    xmlHttp = new ActiveXObject('Microsoft.XMLHTTP')
  }
  var params = []
  if (opt.data) {
    for (var key in opt.data) {
      params.push(key + '=' + opt.data[key])
    }
  }
  xmlHttp.onreadystatechange = function () {
    let state = xmlHttp.readyState
    if (state === 4) {
      let code = xmlHttp.status
      if (code === 403) {
        jumpLogin()
      } else if (code === 200) {
        try {
          let data = JSON.parse(xmlHttp.responseText)
          opt.success(data)
        }
        catch (e) {
          alertMessage(e.message, code)
        }
      } else {
        alertMessage('同步请求错误', code)
      }
    } else {
      console.log('xmlHttp.readyState = ' + state)
    }
  }
  // encodeURIComponent() url部分编码
  var postData = params.join('&')
  if (opt.method.toUpperCase() === 'POST') {
    xmlHttp.open(opt.method, opt.url, opt.async)
    xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
    xmlHttp.send(postData)
  } else if (opt.method.toUpperCase() === 'GET') {
    var url = opt.url
    if (params.length > 0) {
      url = url + '?' + postData
    }
    xmlHttp.open(opt.method, url, opt.async)
    xmlHttp.send()
  }
}

function alertMessage (msg, code) {
  if (msg && msg.length > 0) {
    Message.error({ message: msg })
  } else {
    unknownMessage(code)
  }
}

function unknownMessage (code) {
  let msg = code + ' : 未知错误'
  alertMessage(msg)
}

export const jumpLogin = function (message) {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  Message.error({
    message, onClose: () => {
      if (process.env.NODE_ENV === 'production') {
        window.open(location.origin + '/ques/#/user/login', '_self')
      } else {
        window.open(location.origin + '/#/user/login', '_self')
      }
    }
  })
}
