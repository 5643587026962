<!--HeadImg:Hi, HeadImg 2024/07/14 11:43:25 YuAng-->

<template>
  <el-image v-if="user.face" @click.native="jumpUser" @error="faceError" class="head-img" :class="{ border: border }"
    :style="{ width: size, height: size }" :src="user.face"></el-image>
  <el-image v-else @click.native="jumpUser" class="head-img" :class="{ border: border }"
    :style="{ width: size, height: size }" :src="require('@/assets/images/default_avatar.png')"></el-image>
</template>
<script>
export default {
  name: 'HeadImg',
  props: {
    user: {
      type: Object,
      default: function () {
        return {
          face: ''
        }
      }
    },
    size: {
      type: String,
      default: '40px'
    },
    border: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    faceError () {
      this.user.face = require('@/assets/images/default_avatar.png')
    },
    jumpUser: function () {
      const query = {}
      if (this.$store.state.user) {
        if (this.user.id !== this.$store.state.user.id) {
          query.id = this.user.id
        }
      } else {
        query.id = this.user.id
      }
      this.$router.push({ path: '/user/yingdi', query })
    }
  }
}
</script>

<style scoped>
.head-img {
  border-radius: 50%;
}

.border {
  border: 2px solid rgb(19, 128, 240);
}
</style>
