<!--Lang:切换语言 2024/07/06 12:48:49 YuAng-->

<template>
  <div class="xx-Lang">
    {{ $t('lang') }}：
    <p style="width: 90px;">
      <el-select v-model="locale" @change="localeChange" size="mini">
        <el-option v-for="item in locales" :key="item.name" :value="item.name" :label="item.label"></el-option>
      </el-select>
    </p>
  </div>
</template>
<script>
export default {
  name: 'Lang',
  data () {
    return {
      locales: [
        {
          name: 'zh',
          label: '中文简体'
        },
        {
          name: 'en',
          label: 'English'
        },
        {
          name: 'pt',
          label: 'Português'
        }
      ],
      locale: 'zh'
    }
  },
  mounted () {
    this.locale = this.$store.getters.locale
  },
  methods: {
    localeChange (locale) {
      this.$i18n.locale = locale
      this.$store.commit('setLang', locale)
    }
  }
}
</script>

<style scoped>
.xx-Lang {
  display: flex;
  align-items: center;
}
</style>
