import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ptLocale from 'element-ui/lib/locale/lang/pt'
import ElementLocale from 'element-ui/lib/locale'
const messages = {
  en: {
    ...enLocale,
    site: 'Forum',
    create: 'Create',
    edit: 'Edit',
    search: 'Search',
    delete: 'Delete',
    login: 'Login',
    logout: 'Logout',
    lang: 'Langauge',
    cancel: 'cancel',
    register: 'Register',
    confirm: 'confirm',
    menuHome: 'Home page',
    menuCommunity: 'Community',
    keywordSearch: 'Keywords Search',
    keywordSearchTip: 'Please enter the keywords you want to search for',
    fans: 'Fans',
    attention: 'Follow',
    noAttention: 'no follows for the time being',
    attentioned: 'Followed',
    noFans: 'no fans for the time being',
    po: 'Posts',
    fire: 'Like',
    trample: 'Trample',
    fired: 'Liked',
    trampleed: 'Trampled',
    favorited: 'Favorited',
    favorite: 'Favorite',
    report: 'Report',
    reportType: 'Report Type',
    desc: 'Description',
    reportTip: 'More detailed descriptions can accelerate the trial process',
    okReport: 'Confirm Report',
    addImg: 'Add pictures',
    uploading: 'Uploading...',
    selectLabel: 'Select tags',
    addImgTip: 'Click to enter text content, and add up to three pictures',
    okComment: 'Leave A Comment',
    btnPost: 'Talk About',
    postDialogTitle: 'Post',
    postDialogTip1: 'Title cannot exceed 30 characters',
    postDialogTip2: 'Click to enter text content, and add up to nine pictures',
    publish: 'Publish',
    commentAll: 'Total',
    tiao: 'Comments',
    hotComment: 'Hot Comments',
    hotTiao: '',
    lookMoreBefore: '',
    lookMoreAfter: ' comments left, see more',
    likeBtn: 'Likes',
    mySocial: 'My Social',
    hisSocial: 'His Social',
    setting: 'Profile Settings',
    hisSetting: 'His Profile',
    myYingDi: 'My Camp',
    friendDy: 'Friends Updates',
    notice: 'Notifications',
    replyComment: 'Replies/Comments',
    hisYingDi: 'His Camp',
    hisFire: 'His Likes',
    hisPost: 'His Posts',
    hisComment: 'His Comments',
    hotArticles: 'Campsite Hot List',
    editProfile: 'Edit Profile',
    userInfo: 'User information',
    nickname: 'Nickname',
    intro: 'Introduction',
    confirmModify: 'Confirm modification',
    changeHead: 'Change Avatar',
    uploadImg: 'Upload A Picture',
    uploadImgTip: 'Drag files here or',
    uploadImgTipLink: 'click to upload',
    noContentText: 'No related content found',
    searchContent: 'Article',
    searchUser: 'User',
    getCode: 'Get Verification Code',
    reply: 'Reply',
    reselect: 'Reselect',
    inputContent: 'Please enter the content',
    send: 'Send',
    selectedLabel: 'Selected Tags',
    daysAgo: ' days ago',
    commentIsDeleted: 'This comment has been deleted',
    deletedSuccess: 'Deleted successfully',
    commentSuccess: 'Commented successfully',
    nodata: 'No data available',
    yingDi: 'Camp',
    waiteMoment: 'Uploading, please wait',
    onlyFormat: 'Only JPG, JPEG, PNG, GIF formats are allowed!',
    waiteReview: 'Published successfully, awaiting backend review',
    inputTitle: 'Enter title',
    selectCAndL: 'Select category and label',
    selectReportType: 'Select report type',
    inputReportContent: 'Please fill in the report content',
    reportSuccess: 'Report submitted successfully',
    max5Labels: 'You can select up to 5 labels',
    less1Labels: 'Please select at least one label',
    recommend: 'Recommended',
    hoting: 'Burning Brightly',
    startFire: 'Just Ignited',
    aboutLabels: 'Related Labels',
    comment: 'Comment',
    post: 'Post',
    publishPost: 'Published a post',
    publishComment: 'Posted a comment',
    firePost: ' Liked a post',
    commentYou: ' Commented on you',
    starPost: ' Bookmarked a post',
    attentionSuccess: ' Followed successfully',
    cancelAttention: ' Unfollowed successfully',
    selectPicture: 'Select an image',
    messageToYou: ' Sent you a private message',
    commentYouArticle: ' Commented on your article',
    replyYourComment: ' Replied to your comment',
    attentionYou: ' Followed you',
    likeYourArticle: ' Liked your article',
    likeYourComment: ' Liked your comment',
    starYourArticle: ' Bookmarked your article',
    trampleYourArticle: ' Disliked your article',
    weeksAgo: ' weeks ago',
    monthsAgo: ' months ago',
    hoursAgo: ' hours ago',
    minutesAge: ' minutes ago',
    moment: 'just now',
    sendedCode: 'Verification code sent',
    phoneError: 'Incorrect phone number',
    phoneOrCodeError: 'Incorrect phone number or verification code',
    'input-name': 'please input phone number',
    'input-pwd': 'please input sms code'
  },
  zh: {
    ...zhLocale,
    site: '论坛平台',
    create: '创建',
    edit: '编辑',
    search: '搜索',
    delete: '删除',
    login: '登录',
    logout: '退出',
    lang: '切换语言',
    cancel: '取消',
    register: '注册',
    confirm: '确认',
    menuHome: '首页',
    menuCommunity: '社区',
    keywordSearch: '关键词搜索',
    keywordSearchTip: '请输入要搜索的关键词',
    fans: '粉丝',
    attention: '关注',
    noAttention: '暂无关注',
    attentioned: '已关注',
    noFans: '暂无粉丝',
    po: '发帖',
    fire: '点火',
    trample: '浇水',
    fired: '已点火',
    trampleed: '已浇水',
    favorited: '已收藏',
    favorite: '收藏',
    report: '举报',
    reportType: '举报类型',
    desc: '描述',
    reportTip: '更详实的描述可以加速审理流程',
    okReport: '确认举报',
    addImg: '添加图片',
    uploading: '上传中...',
    selectLabel: '选择标签',
    addImgTip: '点击输入文本内容，图片最多添加3张',
    okComment: '发表评论',
    btnPost: '聊点什么',
    postDialogTitle: '发帖',
    postDialogTip1: '标题不超过30个字符',
    postDialogTip2: '点击输入文本内容，图片最多添加9张',
    publish: '发布',
    commentAll: '全部评论',
    tiao: '条',
    hotComment: '热门评论',
    hotTiao: '条',
    lookMoreBefore: '剩余 ',
    lookMoreAfter: ' 条评论，查看更多',
    likeBtn: '获赞',
    mySocial: '我的社交',
    hisSocial: 'Ta的社交',
    setting: '资料设置',
    hisSetting: 'Ta 的资料',
    myYingDi: '我的营地',
    friendDy: '好友动态',
    notice: '通知',
    replyComment: '回帖/评论',
    hisYingDi: 'Ta的营地',
    hisFire: 'Ta的点火',
    hisPost: 'Ta的发帖',
    hisComment: 'Ta的评论',
    hotArticles: '营地热榜',
    editProfile: '编辑资料',
    userInfo: '用户信息',
    nickname: '昵称',
    intro: '简介',
    confirmModify: '确认修改',
    changeHead: '更换头像',
    uploadImg: '上传图片',
    uploadImgTip: '将文件拖到此处，或',
    uploadImgTipLink: '点击上传',
    noContentText: '没有找到相关内容',
    searchContent: '内容',
    searchUser: '用户',
    getCode: '获取验证码',
    reply: '回复',
    reselect: '重新选择',
    inputContent: '请输入内容',
    send: '发送',
    selectedLabel: '已选标签',
    daysAgo: ' 天前',
    commentIsDeleted: '该评论已被删除',
    deletedSuccess: '删除成功',
    commentSuccess: '评论成功',
    nodata: '暂无数据',
    yingDi: '营地',
    waiteMoment: '正在上传，请稍后',
    onlyFormat: '只能上传 JPG、JPEG、PNG、GIF 格式!',
    waiteReview: '发布成功，等待后台审核通过',
    inputTitle: '请输入标题',
    selectCAndL: '请选择分类和标签',
    selectReportType: '请选择举报类型',
    inputReportContent: '请填写举报内容',
    reportSuccess: '举报成功',
    max5Labels: '最多只能选择 5 个标签',
    less1Labels: '请至少选择一个标签',
    recommend: '推荐',
    hoting: '烧得正旺',
    startFire: '刚刚点燃',
    aboutLabels: '相关标签',
    comment: '评论',
    post: '帖子',
    publishPost: ' 发布了帖子',
    publishComment: ' 发表了评论',
    firePost: ' 给帖子点了火',
    commentYou: ' 评论了你',
    starPost: ' 收藏了帖子',
    attentionSuccess: '关注成功',
    cancelAttention: '取消关注成功',
    selectPicture: '请选择图片',
    messageToYou: ' 给你发了一条私信',
    commentYouArticle: ' 评论了你的文章',
    replyYourComment: ' 回复了你的评论',
    attentionYou: ' 关注了你',
    likeYourArticle: ' 给你的文章点了赞',
    likeYourComment: ' 给你的评论点了赞',
    starYourArticle: ' 收藏了你的文章',
    trampleYourArticle: ' 给你的文章点了踩',
    weeksAgo: ' 周前',
    monthsAgo: ' 月前',
    hoursAgo: ' 小时前',
    minutesAge: ' 分钟前',
    moment: ' 刚刚',
    sendedCode: '验证码已发送',
    phoneError: '手机号输入错误',
    phoneOrCodeError: '手机号或验证码输入错误',
    'input-name': '请输入手机号',
    'input-pwd': '请输入短信验证码'
  },
  pt: {
    ...ptLocale,
    site: 'Fórum',
    create: 'Criar',
    edit: 'Editar',
    search: 'Pesquisar',
    delete: 'Excluir',
    login: 'Entrar',
    logout: 'Sair',
    lang: 'Idioma',
    cancel: 'Cancelar',
    register: 'Registar',
    confirm: 'Confirmar',
    menuHome: 'Página Inicial',
    menuCommunity: 'Comunidade',
    keywordSearch: 'Pesquisa por Palavras-Chave',
    keywordSearchTip: 'Digite as palavras-chave que deseja pesquisar',
    fans: 'Fãs',
    attention: 'Seguir',
    noAttention: 'nenhum seguimento no momento',
    attentioned: 'Seguindo',
    noFans: 'nenhum fã no momento',
    po: 'Publicações',
    fire: 'Curtir',
    trample: 'Descurtir',
    fired: 'Curtido',
    trampleed: 'Descurtido',
    favorited: 'Favoritado',
    favorite: 'Favorito',
    report: 'Denunciar',
    reportType: 'Tipo de Denúncia',
    desc: 'Descrição',
    reportTip: 'Descrições mais detalhadas podem acelerar o processo de análise',
    okReport: 'Confirmar Denúncia',
    addImg: 'Adicionar Imagens',
    uploading: 'Carregando...',
    selectLabel: 'Selecionar Etiquetas',
    addImgTip: 'Clique para inserir conteúdo de texto, até três imagens podem ser adicionadas',
    okComment: 'Deixe um Comentário',
    btnPost: 'Conversar Sobre',
    postDialogTitle: 'Publicação',
    postDialogTip1: 'O título não pode exceder 30 caracteres',
    postDialogTip2: 'Clique para inserir conteúdo de texto, até nove imagens podem ser adicionadas',
    publish: 'Publicar',
    commentAll: 'Total',
    tiao: 'Comentários',
    hotComment: 'Comentários Populares',
    hotTiao: 'Comentários',
    lookMoreBefore: 'Restam ',
    lookMoreAfter: ' comentários, ver mais',
    likeBtn: 'Curtidas',
    mySocial: 'Meu Social',
    hisSocial: 'Seu Social',
    setting: 'Configurações do Perfil',
    hisSetting: 'Seu Perfil',
    myYingDi: 'Meu Campo',
    friendDy: 'Atualizações dos Amigos',
    notice: 'Notificações',
    replyComment: 'Respostas/Comentários',
    hisYingDi: 'Seu Campo',
    hisFire: 'Suas Curtidas',
    hisPost: 'Suas Publicações',
    hisComment: 'Seus Comentários',
    hotArticles: 'Ranking do Campo',
    editProfile: 'Editar Perfil',
    userInfo: 'Informações do Usuário',
    nickname: 'Apelido',
    intro: 'Introdução',
    confirmModify: 'Confirmar Modificação',
    changeHead: 'Alterar Avatar',
    uploadImg: 'Carregar uma Imagem',
    uploadImgTip: 'Arraste os arquivos aqui ou',
    uploadImgTipLink: 'clique para carregar',
    noContentText: 'Nenhum conteúdo relacionado encontrado',
    searchContent: 'Conteúdo',
    searchUser: 'Usuário',
    getCode: 'Obter Código de Verificação',
    reply: 'Responder',
    reselect: 'Re-selecionar',
    inputContent: 'Por favor, insira o conteúdo',
    send: 'Enviar',
    selectedTags: 'Tags Selecionadas',
    daysAgo: ' dias atrás',
    commentIsDeleted: 'Este comentário foi excluído',
    deletedSuccess: 'Excluído com sucesso',
    commentSuccess: 'Comentário feito com sucesso',
    nodata: 'Sem dados disponíveis',
    yingDi: 'Campo',
    waiteMoment: 'Carregando, aguarde',
    onlyFormat: 'Somente formatos JPG, JPEG, PNG, GIF são permitidos!',
    waiteReview: 'Publicado com sucesso, aguardando aprovação do back-end',
    inputTitle: 'Digite o título',
    selectCAndL: 'Selecione categoria e etiqueta',
    selectReportType: 'Selecione o tipo de denúncia',
    inputReportContent: 'Por favor, preencha o conteúdo da denúncia',
    reportSuccess: 'Denúncia enviada com sucesso',
    max5Labels: 'Você pode selecionar até 5 etiquetas',
    less1Labels: 'Por favor, selecione pelo menos uma etiqueta',
    recommend: 'Recomendado',
    hoting: 'Queimando Brilhantemente',
    startFire: 'Acabou de Acender',
    aboutLabels: 'Etiquetas Relacionadas',
    comment: 'Comentário',
    post: 'Publicação',
    publishPost: ' Publicou uma publicação',
    publishComment: ' Fez um comentário',
    firePost: ' Curtiu uma publicação',
    commentYou: ' Comentou com você',
    starPost: ' Marcou uma publicação como favorita',
    attentionSuccess: ' Seguindo com sucesso',
    cancelAttention: ' Deixou de seguir com sucesso',
    selectPicture: ' Selecione uma imagem',
    messageToYou: ' Enviou uma mensagem privada para você',
    commentYouArticle: ' Comentou no seu artigo',
    replyYourComment: ' Respondeu ao seu comentário',
    attentionYou: ' Seguiu você',
    likeYourArticle: ' Curtiu o seu artigo',
    likeYourComment: ' Curtiu o seu comentário',
    starYourArticle: ' Marcou o seu artigo como favorito',
    trampleYourArticle: ' Descurtiu o seu artigo',
    weeksAgo: ' semanas atrás',
    monthsAgo: ' meses atrás',
    hoursAgo: ' horas atrás',
    minutesAge: ' minutos atrás',
    moment: 'agora mesmo',
    sendedCode: 'Código de verificação enviado',
    phoneError: 'O número de telefone está incorreto',
    phoneOrCodeError: 'O número de telefone ou o código de verificação estão incorretos',
    'input-name': 'por favor, insira o número de telefone',
    'input-pwd': 'por favor, insira o código SMS'
  }
}

Vue.use(VueI18n)
// 创建 i18n 实例
const i18n = new VueI18n({
  locale: store.getters.locale, // 设置地区
  messages // 设置地区信息
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

window.i18n = i18n

export default i18n
