import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 全局单例存储
let user = localStorage.getItem('pc-user')
if (user) {
  user = JSON.parse(user)
} else {
  user = { name: '未登录' }
}
const store = new Vuex.Store({
  state: {
    loginVisible: false,
    lang: localStorage.getItem('language') || 'zh',
    classifications: [],
    user
  },
  mutations: {
    login (state, user) {
      if (user.name === '未登录') {
        localStorage.removeItem('pc-token')
        localStorage.removeItem('pc-user')
      } else {
        localStorage.setItem('pc-user', JSON.stringify(user))
      }
      state.user = user
    },
    setLang (state, lang) {
      localStorage.setItem('language', lang)
      state.lang = lang
    },
    setClassifications (state, classifications) {
      state.classifications = classifications
    }
  },
  getters: {
    isTest: function () {
      return process.env.NODE_ENV !== 'production'
    },
    isLogin: function (state) {
      return state.user !== null && state.user !== undefined
    },
    locale: function (state) {
      return state.lang
    },
    classifications: function (state) {
      return state.classifications
    }
  }
})

export default store
