import Vue from 'vue'
Vue.filter('formatDate', function (date, type) {
  if (date !== '0') {
    if (date === '' || date === undefined || date === null) {
      return '--'
    }
  }
  let dateTime = '格式化失败'
  let getDate = date
  if (!(getDate instanceof Date)) {
    getDate = new Date(date)
  }
  const year = getDate.getFullYear()
  const month = getDate.getMonth() + 1
  const day = getDate.getDate()
  if (type === 'yyyy-MM-dd') {
    dateTime = year + '-' + addZero(month) + '-' + addZero(day)
  } else if (type === 'yyyy-M-d') {
    dateTime = year + '-' + month + '-' + day
  } else {
    const hours = getDate.getHours()
    const minutes = getDate.getMinutes()
    dateTime = year + '-' + addZero(month) + '-' + addZero(day) +
      ' ' + addZero(hours) + ':' + addZero(minutes)
  }
  return dateTime
})

function addZero (val) {
  if (val < 10) {
    return '0' + val
  } else {
    return val
  }
}

Vue.filter('formatTimeAgo', function (timestamp) {
  const now = new Date()
  const date = new Date(timestamp)

  const yearDiff = now.getFullYear() - date.getFullYear()
  if (yearDiff > 0) {
    // 如果年份有差异，直接返回具体日期时间
    return date.toISOString().substring(0, 19).replace('T', ' ') // ISO格式转换为更易读的格式，并去除时区部分
  }

  const secondsPast = Math.floor((now.getTime() - timestamp) / 1000)

  let interval
  if (secondsPast >= 3600 * 24 * 7 * 4) {
    // 如果超过大约 4 周，则以月为单位
    interval = Math.floor(secondsPast / (3600 * 24 * 7 * 4))
    return interval + window.i18n.t('monthsAgo')
  } else if (secondsPast >= 3600 * 24 * 7) {
    // 如果超过 7 天，则以周为单位
    interval = Math.floor(secondsPast / (3600 * 24 * 7))
    return interval + window.i18n.t('weeksAgo')
  } else if (secondsPast >= 3600 * 24) {
    // 如果超过 24 小时，则以天为单位
    interval = Math.floor(secondsPast / (3600 * 24))
    return interval + window.i18n.t('daysAgo')
  } else if (secondsPast >= 3600) {
    // 如果超过 1 小时，则以小时为单位
    interval = Math.floor(secondsPast / 3600)
    return interval + window.i18n.t('hoursAgo')
  } else if (secondsPast >= 60) {
    // 如果超过 1 分钟，则以分钟为单位
    interval = Math.floor(secondsPast / 60)
    return interval + window.i18n.t('minutesAge')
  } else {
    return window.i18n.t('moment')
  }
})
