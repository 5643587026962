import Vue from 'vue'
import i18n from '@/utils/i18n'
import App from './App.vue'
import '@/utils/filter'
import store from '@/store'
import router from '@/router'
import ElementUI from 'element-ui'
Vue.config.productionTip = false
Vue.use(ElementUI)

// 解决ie9-bug： el-input 删除完内容，失去焦点后又展示删除之前的内容 panyanzhi
Vue.prototype.changeValue = function (e) {
  return e.target.value
}

new Vue({
  i18n,
  render: h => h(App),
  router,
  store
}).$mount('#app')
