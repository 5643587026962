<!--LoginMenu:Hi, LoginMenu 2024/07/13 12:19:50 YuAng-->

<template>
  <el-dropdown @command="handleCommand">
    <head-img :user="user"></head-img>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item disabled>{{ user.name }}</el-dropdown-item>
      <el-dropdown-item divided command="/user/setting">{{ $t('editProfile') }}</el-dropdown-item>
      <el-dropdown-item command="/user/yingdi">{{ $t('myYingDi') }}</el-dropdown-item>
      <el-dropdown-item command="/user/notify">{{ $t('replyComment') }}</el-dropdown-item>
      <el-dropdown-item divided command="logout">{{ $t('logout') }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import HeadImg from '../../components/HeadImg.vue'
export default {
  name: 'LoginMenu',
  components: { HeadImg },
  computed: {
    user: function () {
      return this.$store.state.user
    }
  },
  methods: {
    handleCommand (command) {
      if (command === 'logout') {
        this.logout()
      } else {
        this.$router.push(command)
      }
    },
    logout () {
      this.$store.commit('login', { name: '未登录' })
      location.reload()
    }
  }
}
</script>
