import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history', // 使用history模式
  base: process.env.VUE_APP_ROUTER_PREFIX, // 基础路径，从环境变量中获取
  routes: [
    {
      path: '/',
      component: () => import('@/views/home/index.vue'),
      name: 'Home',
      meta: {
        title: '首页'
      }
    },
    {
      path: '/user',
      component: () => import('@/views/user/index.vue'),
      name: 'UserIndex',
      meta: {
        title: '个人中心'
      },
      redirect: '/user/yingdi',
      children: [
        {
          path: '/user/yingdi',
          component: () => import('@/views/user/yingdi.vue'),
          name: 'YingDi',
          meta: {
            title: '我的营地'
          }
        },
        {
          path: '/user/friendnews',
          component: () => import('@/views/user/friendnews.vue'),
          name: 'FriendNews',
          meta: {
            title: '好友动态'
          }
        },
        {
          path: '/user/fire',
          component: () => import('@/views/user/fire.vue'),
          name: 'Fire',
          meta: {
            title: '点火'
          }
        },
        {
          path: '/user/collected',
          component: () => import('@/views/user/collected.vue'),
          name: 'Collected',
          meta: {
            title: '收藏'
          }
        },
        {
          path: '/user/history',
          component: () => import('@/views/user/history.vue'),
          name: 'History',
          meta: {
            title: '足迹'
          }
        },
        {
          path: '/user/notify',
          component: () => import('@/views/user/notify.vue'),
          name: 'Notify',
          meta: {
            title: '回帖/评论'
          }
        },
        {
          path: '/user/notice',
          component: () => import('@/views/user/notice.vue'),
          name: 'Notice',
          meta: {
            title: '通知'
          }
        }
      ]
    },
    {
      path: '/user/setting',
      component: () => import('@/views/user/setting.vue'),
      name: 'Setting',
      meta: {
        title: '用户设置'
      }
    },
    {
      path: '/community',
      component: () => import('@/views/community/index.vue'),
      name: 'Community',
      meta: {
        title: '社区'
      }
    },
    {
      path: '/search',
      component: () => import('@/views/search/index.vue'),
      name: 'Search',
      meta: {
        title: '搜索',
        fullPage: true
      }
    },
    {
      path: '/tag/:id',
      component: () => import('@/views/tags/index.vue'),
      name: 'Tags',
      meta: {
        title: '热门推荐'
      }
    },
    {
      path: '/post/:id',
      component: () => import('@/views/post/index.vue'),
      name: 'Post',
      meta: {
        title: '详情'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = '论坛项目 - ' + to.meta.title
  }
  next()
})

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
